<template>
  <div id="refund-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2
            class="font-medium text-base"
            :class="isRTL ? 'ml-auto' : 'mr-auto'"
          >
            <app-i18n code="wallets.recharge"></app-i18n>
          </h2>
        </div>
        <div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
            <div class="col-span-12">
              <label for="refundAmount" class="form-label">{{
                i18n('wallets.rechargeAmount')
              }}</label>
              <input
                id="refundAmount"
                v-model="amount"
                type="number"
                class="form-control w-full"
              />
            </div>
            <div class="col-span-12">
              <label for="modal-form-3" class="form-label">{{
                i18n('wallets.notes')
              }}</label>
              <textarea
                id="body-en"
                :rows="4"
                class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                v-model="notes.en"
                :placeholder="i18n('notification.fields.english')"
              ></textarea>
              <textarea
                id="body-ar"
                :rows="4"
                class="form-control w-full dark:bg-dark-9 dark:placeholder-white mt-2"
                v-model="notes.ar"
                :placeholder="i18n('notification.fields.arabic')"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer flex justify-center items-center gap-2">
            <AppButton
              id="submit-btn"
              type="button"
              data-dismiss="modal"
              class="btn bg-theme-36 text-white cursor-pointer whitespace-nowrap"
              :class="!refundLoading ? 'w-24' : ''"
              :disabled="refundLoading"
              :loading="refundLoading"
              @click="doSubmit"
            >
              <strong>{{ i18n('wallets.recharge') }}</strong>
              <template v-slot:loading>
                <span>{{ i18n('common.loading') }}</span>
                <LoadingIcon
                  icon="three-dots"
                  color="#FFFFFF"
                  style="margin: 0 4px"
                />
              </template>
            </AppButton>
            <button
              type="button"
              data-dismiss="modal"
              class="btn bg-theme-31 text-black w-24"
            >
              {{ i18n(`common.cancel`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  name: 'app-refund-modal',
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      amount: undefined,
      notes: {
        en: '',
        ar: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      refundLoading: 'user/view/refundLoading'
    })
  },
  methods: {
    ...mapActions({
      doRefund: 'user/view/doRefund'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doSubmit() {
      if (!this.amount || this.amount <= 0) {
        Message.error(
          this.isRTL ? 'ادخل قيمه الاسترداد' : 'enter the refund amount'
        )
        return false
      }
      if (!this.notes.en.length) {
        Message.error(
          this.isRTL ? 'أدخل ملاحظة للمتابعة' : 'enter a note to procced'
        )
        return false
      }
      if (!this.notes.ar.length) {
        Message.error(
          this.isRTL ? 'أدخل ملاحظة للمتابعة' : 'enter a note to procced'
        )
        return false
      }
      await this.doRefund({
        id: this.id,
        amount: parseFloat(this.amount),
        notes: this.notes
      })
      this.amount = undefined
      this.notes = {
        en: '',
        ar: ''
      }
      cash('#refund-modal').modal('hide')
    }
  }
}
</script>
