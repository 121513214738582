<template>
  <GoogleMapLoader :init="init" />
</template>

<script>
/* eslint-disable */
import { defineComponent, watch, computed } from 'vue'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import { useStore } from '@/store'

export default defineComponent({
  props: {
    options: {
      type: Object
    },
    markersLocations: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const init = async (initializeMap) => {
      const { google, map } = await initializeMap({
        apiKey: 'AIzaSyBfuKMUnoTBYHwQCcyZV-5k-3vw0r200vo',
        config(google) {
          return {
            zoom: props.options.zoom || 15,
            styles: [],
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            streetViewControl: props.options.streetViewControl || false,
            center: {
              lat: props.options.location.lat,
              lng: props.options.location.lng
            }
          }
        }
      })

      const infoWindow = new google.maps.InfoWindow({
        minWidth: 'fit-content',
        maxWidth: 400
      })
      // new google.maps.Polyline({
      //   path: [
      //     new google.maps.LatLng(31.2340672, 29.9519467),
      //     new google.maps.LatLng(31.2340672, 29.98)
      //   ],
      //   strokeColor: '#FF0000',
      //   strokeOpacity: 1.0,
      //   strokeWeight: 10,
      //   geodesic: true,
      //   map: map
      // })
      new MarkerClusterer(
        map,
        props.markersLocations.map((markerElem) => {
          const point = new google.maps.LatLng(
            parseFloat(markerElem.latitude),
            parseFloat(markerElem.longitude)
          )
          const infowincontent = `
            <div class="font-medium">
              ${markerElem.name}
            </div>
            <div class="mt-1 text-gray-600">
              Latitude: ${markerElem.latitude}, Longitude: ${markerElem.longitude}
            </div>`
          const marker = new google.maps.Marker({
            map: map,
            position: point,
            icon: {
              url:
                markerElem.marker || require('@/assets/images/map-marker.png')
            }
          })

          google.maps.event.addListener(marker, 'click', function () {
            infoWindow.setContent(infowincontent)
            google.maps.event.addListener(infoWindow, 'domready', function () {
              cash('.arrow_box').closest('.gm-style-iw-d').removeAttr('style')
              cash('.arrow_box')
                .closest('.gm-style-iw-d')
                .attr('style', 'overflow:visible')
              cash('.arrow_box')
                .closest('.gm-style-iw-d')
                .parent()
                .removeAttr('style')
            })

            infoWindow.open(map, marker)
          })
          return marker
        }),
        {
          styles: [
            {
              width: 51,
              height: 50,
              textColor: 'white',
              url: require('@/assets/images/map-marker-region.png'),
              anchor: [0, 0],
              anchorText: [19, 0],
              fontWeight: 'bold'
            }
          ]
        }
      )

      const stop = watch(darkMode, () => {
        init(initializeMap)
        stop()
      })
    }

    return {
      init
    }
  }
})
</script>
