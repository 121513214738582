<template>
  <div id="rate-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2
            class="font-medium text-base"
            :class="isRTL ? 'ml-auto' : 'mr-auto'"
          >
            <app-i18n code="common.rateUser"></app-i18n>
          </h2>
        </div>
        <div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
            <div class="col-span-12">
              <label for="rating" class="form-label">{{
                i18n('common.rating')
              }}</label>
              <input
                id="rating"
                v-model="rating"
                type="number"
                min="1"
                max="5"
                class="form-control w-full"
              />
            </div>
            <div class="col-span-12">
              <label for="review" class="form-label">{{
                i18n('common.review')
              }}</label>
              <textarea
                id="review"
                :rows="4"
                class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                v-model="review"
                :placeholder="i18n('common.review')"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer flex justify-center items-center gap-2">
            <AppButton
              id="submit-btn"
              type="button"
              data-dismiss="modal"
              class="btn bg-theme-36 text-white cursor-pointer whitespace-nowrap"
              :class="!rateLoading ? 'w-24' : ''"
              :disabled="rateLoading"
              :loading="rateLoading"
              @click="doSubmit"
            >
              <strong>{{ i18n('common.addRate') }}</strong>
              <template v-slot:loading>
                <span>{{ i18n('common.loading') }}</span>
                <LoadingIcon
                  icon="three-dots"
                  color="#FFFFFF"
                  style="margin: 0 4px"
                />
              </template>
            </AppButton>
            <button
              type="button"
              data-dismiss="modal"
              class="btn bg-theme-31 text-black w-24"
            >
              {{ i18n(`common.cancel`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  name: 'app-rate-modal',
  props: {
    id: {
      type: String
    },
    userType: {
      type: String
    }
  },
  data() {
    return {
      rating: 0,
      review: ''
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      rateLoading: 'user/view/rateLoading'
    })
  },
  methods: {
    ...mapActions({
      doRate: 'user/view/doRate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doSubmit() {
      if (this.rating < 0 || this.rating > 5) {
        Message.error(
          this.isRTL
            ? 'التقييم يمبغى ان يكون قيمه بين 0 و 5'
            : 'Rating must be a value between 0 and 5'
        )
        return false
      }
      await this.doRate({
        id: this.id,
        userType: this.userType,
        dashboardRate: {
          rating: this.rating,
          review: this.review
        }
      })
      this.rating = 0
      this.review = ''
      cash('#rate-modal').modal('hide')
    }
  }
}
</script>
